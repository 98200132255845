import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { GlobalStateContext } from "../../context/contextProvider"

interface PortraitProps {
  imgSrc: any;
  visible: boolean;
}

const Portrait = ({ imgSrc, visible }: PortraitProps) => (
  <Img
    fluid={imgSrc.childImageSharp.fluid}
    loading="eager"
    alt="Paul Gaumer"
    data-testid="hero-image"
    className={`object-cover object-top w-full h-full rounded-lg ${visible ? "block" : "hidden"}`} />
)

const HeroPortrait = () => {
  const { themeColor } = useContext(GlobalStateContext)
  const data = useStaticQuery(
    graphql`
      query {
        paulYellow: file(relativePath: { eq: "paul-yellow.jpg" }) {
          childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        paulRed: file(relativePath: { eq: "paul-red.jpg" }) {
          childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        paulBlue: file(relativePath: { eq: "paul-blue.jpg" }) {
          childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        paulGreen: file(relativePath: { eq: "paul-green.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Portrait imgSrc={data.paulYellow} visible={themeColor === "yellow"} />
      <Portrait imgSrc={data.paulRed} visible={themeColor === "red"} />
      <Portrait imgSrc={data.paulBlue} visible={themeColor === "blue"} />
      <Portrait imgSrc={data.paulGreen} visible={themeColor === "green"} />
    </>
  )
}

export default HeroPortrait
